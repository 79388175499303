<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
              <li>{{ vehicle_repair.id }}</li>
            </ul>
            <div class="header-actions">
              <p></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <!--<li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>-->
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div class="admin-body">

            <form @submit="update_repair" class="form" method="post">

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="start">Reparatur Beginn</label>
                    <input v-model="vehicle_repair.start" class="form-input" type="datetime-local" name="start" id="start" placeholder="01.01.2020 18:00">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="end">Reparatur Ende</label>
                    <input v-model="vehicle_repair.end" class="form-input" type="datetime-local" name="end" id="end" placeholder="01.01.2020 22:00">
                  </div>
                </div>
              </div>

              <div class="form-wrap">
                <label for="description">Beschreibung</label>
                <textarea v-model="vehicle_repair.description" rows="5" class="form-input" type="text" name="description" id="description" placeholder="Art der Reparatur..."></textarea>
              </div>

              <p v-if="vehicle_repair.image"><img :src="vehicle_repair.image" width="200"></p>

              <div class="form-wrap">
                <label>Bild</label>
                <input type="file" @change="set_image">
              </div>

              <p>
                <input type="submit" value="Speichern ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'
import moment from 'moment'

export default {
  name: 'new_admin_vehicle_repair',
  components: {
    AdminNav
  },
  data () {
    return {
      vehicle: {},
      vehicle_repair: {},
      temp_image: "",
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle = response.data.vehicle;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/repairs/'+this.$route.params.repair_id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_repair = response.data.vehicle_repair;
        this.vehicle_repair.start = moment(response.data.vehicle_repair.start).format("YYYY-MM-DDThh:mm");
        this.vehicle_repair.end = moment(response.data.vehicle_repair.end).format("YYYY-MM-DDThh:mm");
      })

    },
    update_repair: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/repairs/'+this.vehicle_repair.id, this.vehicle_repair, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.',
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    },
    set_image(event) {
      this.temp_image = event.target.files[0];
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
